@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

$main-text-color: #171d3c;
$type-text-color-active: #205FF4;
$type-text-color: #34446A;
$color-blue-1: #2A3884;
$color-blue-2: #848FCE;
$color-blue-3: #354199;
$color-blue-4: #2A3883;
$color-blue-5: #5E6AB0;
$color-button-blue: #205FF4;
$color-black: #001741;
$color-red: #F05E6A;
$color-orange: #F17F21;
$font-nunito-sans: 'Nunito Sans', sans-serif;
$bg-page: #F8FAFC;
$width-section: calc(100vw - (32px * 2));