@import '../Sass/variable';

.App {
    height: 100%;
    min-height: 100vh;
    background-color: $bg-page;
}

.Main{
    &.checkout {
        padding: 0 0 10px 0;
    }

    &.tablet {
        height: 100%;
        min-height: 100vh;
    }

    &.landscape {
        height: 100% !important;
        min-height: 100vh;
    }

    &.marker {
        height: 100vh !important;
    }
}

.contentContainer {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 70px;

    &.tablet {
        gap: 2rem !important;
        grid-template-columns: 1fr 1fr !important;
    }
}

.back {
    text-decoration: none;
    position: absolute;
    left: 16px;
}

.cardCurrent {
    cursor: pointer;
    width: $width-section;
    margin: 16px;
    background-color: #205FF4;
    box-shadow: 0px 4px 31px rgba(32, 95, 244, 0.21);
    border-radius: 6px;
    color: white;
    padding: 16px;
    position: relative;
    height: fit-content;

    .image {
        position: absolute;
        top: 50px;
        right: 16px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        margin: 8px 0;

        &.title {
            font-size: 12px;
        }

        &.plan {
            font-family: 'Montserrat', sans-serif;
            font-size: 24px;
            font-weight: bold;
            margin: 8px 0 24px 0;
        }
    }

    &.individual {
        background-color: #2A3883 !important;
    }

    &.sch {
        background-color: $color-red !important;
    }

    &.tablet {
        position: fixed;
        width: 45% !important;
    }
}

.paymentScheme {
    width: calc(100% - 32px);
    margin: 20px 16px;

    &.bundle_success {
        width: 60% !important;
    }
    
    p {
        color: $color-blue-1;
        font-size: 16px;
        font-weight: 700;

        &.desc {
            color: $color-blue-2;
            font-size: 11px;
            font-weight: 400;

            
            &.method {
                height: max-content;
                margin: auto 0;
            }
            
        }
    }
    
    .custom {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        color: $color-blue-1;
        gap: 2px;

        >div {
            padding: 0 8px;
            display: flex !important;
            justify-content: left !important;
            gap: 2px;
            align-items: center !important;
            
            >input {
                text-align: center;
                padding: 5px;
                width: 40px;
                color: $color-blue-1;
                border: 1px solid #D3D6E9;
                border-radius: 6px;
                font-size: 14px !important;
                font-weight: 800;
            }
            
            >span {
                color: $color-blue-1;
                cursor: pointer;
            }
        }

        >p {
            font-size: 18px !important;
            margin: 0px !important;
            padding: 0px 8px !important;
        }

    }

    .paymentWrapper {
        display: grid;
        justify-content: space-between;
        gap: 16px;
        grid-template-columns: 1fr 1fr;
        
        >div {
            cursor: pointer;
            width: 100%;
            background-color: white;
            border-radius: 6px;
            padding: 16px 0;
            border: 1px solid #d3d6e90e;
            p {
                // padding: 0 16px;
                margin: 4px 0;

                >input {
                    margin: 5px 12px 5px 0;
                    cursor: pointer;
                }
            }
            
            &.active {
                border: 1px solid #205FF4;
            }

            &.period {
                text-align: center;
                min-height: 80px;

                >p {
                    font-size: 14px;
                    font-weight: 600;

                    &.desc {
                        font-size: 20px;
                        font-weight: 700;
                        color: $color-blue-1;
                    }
                }
            }
            &.period-custom {
                text-align: center;
                padding: 8px 0 !important;
                
                >p {
                    font-size: 14px;
                    font-weight: 600;

                    &.desc {
                        font-size: 16px;
                        font-weight: 700;
                        color: $color-blue-1;
                    }
                }
            }

            &.method {
                display: flex;
                justify-content: space-between;
                width: calc(100% - 32px);
                padding: 16px;

                >p {
                    padding: 0px;
                }
            }

            &.seats {
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: start;
                width: 100% !important;
                padding: 16px;
                cursor: default;

                >span {
                    color: $color-blue-1;
                    cursor: pointer;
                }
                
                >input {
                    text-align: center;
                    padding: 10px 5px;
                    width: 15%;
                    color: $color-blue-1;
                    border: 1px solid #D3D6E9;
                    border-radius: 6px;
                    font-size: 16px;
                    font-weight: 800;
                }

                >p {
                    width: 30%;
                    font-size: 16px;
                    font-weight: 800;
                }

                >div {
                    width: 50%;
                    text-align: end;

                    >p {
                        font-weight: 800;
                        padding: 0px;
                        color: $color-blue-1;

                        &.desc {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        &.paymentMethod {
            grid-template-columns: 1fr !important;
        }

        .button {
            background-color: $color-button-blue;
            width: calc(100% - 32px);
            margin: 32px 16px;
            padding: 13px 0;
            color: white;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            border-radius: 6px;
            cursor: pointer;
        }

        &.seats {
            grid-template-columns: 1fr !important;
            margin-right: 32px;
        }
    }

    .prices {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 32px);
        padding: 0px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        
        >p {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.2);
            font-weight: 800;
            
            &.active {
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }

    &.tablet {
        width: auto !important;
    }
}

.checkout-button {
    width: 100%;
    position: fixed;
    bottom: 0px;
    border: 1px solid #DFE8FF;
    padding: 10px 0% !important;
    display: flex;
    justify-content: center;
    background: white;

    &.right-button {
        justify-content: right !important;
    }
}

.button {
    background-color: $color-button-blue;
    width: calc(50% - 16px - 2rem);
    margin: 8px 16px;
    padding: 13px 0;
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}

.disable {
    opacity: 30%;
    cursor: default !important;
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    border-bottom: 5px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.loader2 {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-top: 20px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.25);
    padding: 3px;
}

.loader2 {
    display: block;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    height: 30px;
    border-radius: 50%;
    animation: spin 1s ease infinite;
    -webkit-animation: spin 1s infinite;
    border: 3px solid transparent;
    border-top-color: #24B9E1;
    background-color: #fff;
    box-sizing: border-box;
}
  
  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.desc-end-user-container {
    display: flex;
    gap: .5rem;
}

.desc-end-user {
    color: #2A3884;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
}

.voucher-container {
    text-align: left;
    // padding: 10px;
    // margin: 0px 12px;
    overflow: hidden;
    width: 100%;
    border: none;
    background: #FFFFFF;
    border-radius: 6px;
    font-size: 14px !important;
    display: flex;
    justify-content: space-between;
}

.voucher-input {
    color: $color-blue-1 !important;
    border: none;
    width: 70%;
    font-size: 16px !important;
    padding: 10px 16px;
    font-weight: 800;
}

.voucher-view {
    width: 30%;
    padding: 0 8px;
    background: #24B9E1;
    border-radius: 0px 6px 6px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    &.button-search {
        cursor: pointer;
    }

    >p {
        color: white;
        padding: 0px !important;
        margin: 0px !important;
    }

    >span {
        color: white;
    }
}

.button-disable {
    cursor: not-allowed !important;
}

.error-voucher {
    color: $color-red !important;
    font-size: 14px !important;
}

.custom-price-discount {
    display: flex;
    flex-direction: column;
}

.search-button-blue {
    background-color: #205FF4 !important;
}

.search-button-red {
    background-color: #FF0000 !important;
}

.error-status-voucher {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    >p {
        color: white !important;
    }
}