@import '../Sass/_variable.scss';

.Main {
    &.status {
        height: 100vh;

        >.nav >.header >.close-popup {
            top: 10px;
        }

        .new {
            justify-content: space-between;
        }

        .contents {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: auto;
            position: relative;

            .button {
                background-color: $color-button-blue;
                width: calc(100% - 32px);
                margin: 32px 16px;
                padding: 13px 0;
                color: white;
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                cursor: pointer;
                &.failed {
                    background-color: $color-orange !important;
                }
            
                &.waiting {
                    margin: 12px 16px;
            
                    &.down {
                        background-color: white !important;
                        color: $color-button-blue !important;
                        border: 2px solid $color-button-blue;
                    }
                }
            
                &.tablet {
                    width: calc(100% - 20vw) !important;
                }
            }

            .top-banner {
                height: 100%;

                > .checkout-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    height: 100%;
                    position: relative;

                    > .image {
                        max-width: 40px;
                        max-height: 40px;
                        margin: 20px auto;

                        &.left {
                            width: 35%;
                            max-width: none;
                            max-height: none;
                            position: absolute;
                            left: 0px;
                            top: 0px;
                        }

                        &.right {
                            width: 35%;
                            max-width: none;
                            max-height: none;
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            transform: scaleX(-1);
                        }
                    }

                    > .checkout-name {
                        font-weight: 800;
                        font-size: 20px;
                        line-height: 26px;
                        display: flex;
                        align-items: flex-end;
                        text-align: center;
                        color: #34446A;
                        -webkit-text-fill-color: initial;
                        background-color: transparent;
                    }

                    > .checkout-desc {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 26px;
                        display: flex;
                        align-items: flex-end;
                        text-align: center;
                        color: #34446A;
                    }

                    > section {
                        position: relative;
                        padding: 10px;
                        width: calc(100vw - 40px);
                        max-width: 400px;
                        height: auto;
                        background: #FFFFFF;
                        box-shadow: 0px 19px 46px rgb(0 0 0 / 9%);
                        border-radius: 8px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: flex-start;
                        min-height: 120px;

                        > .checkout-img {
                            min-width: 100px;
                            min-height: 100px;
                            filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.25));
                            border-radius: 8px;
                            background: #D1D7D5;
                            width: 100px;
                            height: 100px;
                        }

                        > section {
                            margin: 0px 16px;
                            width: 100%;

                            > h4 {
                                width: 60%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }

                    > .button-view {
                        border: none;
                        background: #14CF69;
                        border-radius: 8px;
                        font-size: 20px;
                        padding: 16px 20px;
                        font-weight: 600;
                        color: #34446A;
                        position: absolute;
                        bottom: 20px;
                        width: calc(100%);
                        margin: 0px 20px;
                        filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.25));
                        cursor: pointer;
                    }
                }
                > img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    max-width: 480px;
                }

                h1, h3, .success-button-container { text-align: center; }
            
                h1 {
                    font-weight: 800;
                    font-size: 24px;
                    background: linear-gradient(109.69deg, #498729 0%, #62BE38 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }

                h3 {
                    color: #818DAB;
                }

                .banner-bundle {
                    max-width: 400px;
                    margin-inline: auto;

                    .banner-image {
                        border-radius: 20px;
                        overflow: hidden;
                        max-height: 200px;
                        margin-bottom: 10px;

                        img { height: 100%; }
                    }

                    div:last-child {
                        display: flex;
                        justify-content: space-between;

                        span {
                            font-size: 18px;

                            &:last-child {
                                white-space: nowrap;
                            }
                        }
                    }
                }

                .success-button-container {
                    width: 100%;
                    position: fixed;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-inline: auto;
                    border-top: 1px solid #D3D6E9;
                    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
                    padding: 16px;

                    .success-button {
                        width: 100%;
                        background-color: #205FF4;
                        color: #fff;
                        border-radius: 8px;
                        padding: 12px;
                        max-width: 400px;
                        margin-inline: auto;
                        cursor: pointer;

                        &.green-button { background: linear-gradient(109.69deg, #498729 0%, #62BE38 100%); }
                    }
                }

                .blr-desc {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;

                    >.blr-icon {
                        width: 28px;
                        height: 28px;
                        filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.42));
                    }

                    span {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 100.3%;
                        color: #E7B465;

                        &:first-of-type { font-size: 24px; }
                    }
                }
            }
        }
    
        .header {
            font-size: 18px;
            color: $color-blue-1;
            font-weight: 700;
            
            &.desc {
                width: calc(100% - 20vw) !important;
                font-size: 12px;
                color: $color-button-blue !important;
                text-align: center;
                font-weight: 300;
                padding: 0 10vw;
            }
        }
    }
}

.image-retina {
    width: 50% !important;

    &.popup-image {
        max-width: 200px !important;
    }
}

@media only screen and (max-width: 425px) {
    .top-banner {
        h1, h3, section {
            padding-inline: 20px;
        }

        .success-button {
            max-width: 250px !important;
        }
    }
}

.pro-signed {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none !important;
    &.pro-emblem {
      background: #34446a;
      box-sizing: border-box;
      padding: 5px 10px;
      border-radius: 24px;
      font-weight: 700;
      font-size: 12px;
      font-family: "Nunito Sans";
      opacity: 1;
      transition: opacity 0.3s;
      background-color: #f6a623;
      color: #001741;
      margin-left: 16px;
  
      img {
        margin-right: 5px;
        width: 16px !important;
      }
    }
    &.special {
      background: linear-gradient(
        94.79deg,
        #8398f8 4.52%,
        #db1d8f 48.12%,
        #f89e03 92.64%
      ) !important;
      color: white !important;
  
      &.header-emblem {
        margin-left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        width: 85px;
        height: 24px;
        background: linear-gradient(
          94.79deg,
          rgba(131, 152, 248, 0.8) 4.52%,
          rgba(219, 29, 143, 0.8) 48.12%,
          rgba(248, 158, 3, 0.8) 92.64%
        );
        border-radius: 50px;
  
        img {
          margin-right: 5px;
          width: 16px !important;
        }
      }
    }
  }