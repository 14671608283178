@import '../Sass/_variable.scss';

.progress-card {
    background-color: white;
    padding: 12px 18px;
    margin: 0px 16px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 76px;
    z-index: 10;

    p {
        font-size: 12px;
        color: $color-blue-5;
        font-weight: bold;
        width: auto;
        max-width: 35%;
    }

    .icon {
        width: 48px !important;
        height: 48px !important;
        cursor: pointer;
    }
}

.progress-border {
    background-color: #DFE8FF;
    border-radius: 8px;
    height: 10px;
    width: 40%;
    min-width: 160px;
    display: flex;
    align-items: center;

    &.tablet {
        width: 60% !important;
    }
}

.progress-bar {
    background-color: #4770d6;
    border-radius: 8px;
    height: 6px;
    margin: 2px;
    transition: 0.5s;
}

.progress-section {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.class-seats {
    width: calc(100% - 32px);
    margin: 32px 16px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    align-items: center;

    &.desktop {
        grid-template-columns: 1fr 1fr !important;
    }
}

.seats-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    width: calc(100% - 16px);

    >.card-info {
        display: flex;
        align-content: center;
        gap: 1rem;

        >.thumbnail {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            border: white 1px solid;
            background-color: rgba(128, 128, 128);
            position: relative;

            &.selected {
                width: 20vw !important;
                max-width: 60px !important;
                height: 20vw !important;
                max-height: 60px !important;
            }
        }

        >.detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p {
                margin: 0px;
            }
        }

        &.selected {
            position: relative;
            gap: 5px;
            align-items: center;
            flex-direction: column !important;
        }

        >.close-icons {
            cursor: pointer;
        }
    }

    &.search {
        width: calc(100% - 36px);
    }

    &.selected {
        // min-width: 60px !important;
        width: auto !important;
    }

    >.icon {
        cursor: pointer;
    }
}

.loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.class-scrim {
    background-color: rgba(49, 49, 49, 0.2);
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0px;
    z-index: 11;

    &.hidden {
        display: none;
    }
}

.not-found {
    color: red;
    font-size: 16px;
    font-weight: 600;
}

.class-add {
    background-color: white;
    width: 100vw;
    height: 70vh;
    max-height: 80%;
    z-index: 12;
    position: fixed;
    bottom: 0px;
    border-radius: 1rem;
    box-shadow: 0px 15px 46px rgba(170, 170, 170, 0.15);
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    align-items: center;
    gap: 1rem;
    overflow: scroll;

    .header-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: sticky;
        top: 0px;
        background-color: white;
        z-index: 10;
        padding: 4px 0;
        gap: 1rem;
        
        .class-hide {
            border-radius: 1rem;
            background-color: #CBCBCB;
            height: 4px;
            width: 30%;
            max-width: 200px;
            margin: 1rem 0;
            display: flex;
            justify-content: center;
            align-items: center;
    
            span {
                cursor: pointer;
                position: relative;
                top: 1rem;
                color: #838383;
                animation: bounce 1.5s infinite;
            }
        }

        .class-close {
            width: 85%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 25px;
                font-weight: bold;
                color: $color-black;
                margin: 15px 0;
            }

            .icon {
                width: 36px !important;
                height: 36px !important;
                cursor: pointer;
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }

        >.search-bar {
            background: #FFFFFF;
            box-shadow: 0px 15px 46px rgba(170, 170, 170, 0.15);
            border-radius: 20px;
            height: 2rem;
            width: 80%;
            border: rgba(131, 131, 131, 0.3) 1px solid;
            display: flex;
            align-items: center;
            padding: 2px 15px;
            color: #818DAB;

            >input {
                margin: 2px 10px;
                height: 100%;
                width: 100%;
                border: transparent;
            }
        }

        .container-list {
            width: 85%;
            font-size: 16px;
            color: $color-black;
            font-weight: bold;
            transition: 0.5s;

            >.user-list {
                display: flex;
                justify-content: left;
                overflow-x: scroll;

                .close-icons {
                    position: absolute;
                    right: -8px;
                    top: 0px;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    &.tablet {
        // position: fixed !important;
        max-width: 700px !important;
        height: calc(100vh / 4 * 3) !important;
        transform: translate(calc((100vw - 700px) / 2), -15%) !important;
    }

    &.hidden {
        height: 0px !important;
        max-height: 0px !important;
    }
}

input, select, textarea, ::placeholder{
    color: #818DAB;
    font-weight: 500;
}

input:focus {
    outline: none !important;
}

.hidden-icons {
    display: none !important;
}

@keyframes bounce {
    0% {
        transform: scale(1,1) translate(0px, 3px);
    }

    30%{
        transform: scale(1,0.8) translate(0px, 5px); 
    }

    60%{
        transform: scale(1,1.1) translate(0px, -5px); 
    }

    100% {
        transform: scale(1,1) translate(0px, 3px);
    }
}

.popup-container {
    display: flex;
    position: fixed;
    top: 0px;
    z-index: 100000;
    width: 100vw;
    // max-width: 100%;
    height: 100vh;
    // max-height: 100%;
    justify-content: center;
    align-items: center;

    &.hidden {
        display: none !important;
    }
}

.error-popup {
    z-index: 1000000;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0px 15px 46px rgba(170, 170, 170, 0.15);
    position: absolute;
    width: 60%;
    max-width: 300px;
    padding: 10px 15px;

    h2 {
        font-size: 16px;
        text-align: center;
        color: red;
        text-transform: capitalize;
    }

    p {
        font-size: 12px;
        text-align: center;
        padding: 0 15px;
    }

    .icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
    }
}