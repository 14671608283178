@import '../Sass/_variable.scss';

.Main {
    font-family: $font-nunito-sans;
    padding: 0 0 20px 0;
    position: relative;
    background-color: transparent;
    min-height: 100vh;

    &.marketplace {
        border: 1px solid #DFE8FF;
    }

    &.tablet-overview {
        height: 100% !important;
    }

    &.overview {
        height: 100vh;
    }

    &.arMarker {
        height: 100%;
    }

    &.redeem {
        height: 100% !important;
        min-height: 100vh !important;
        padding: 0px !important;
    }

    &.embed {
        height: 100vh !important;
    }
}

.sliderSection {
    display: flex;
    flex-direction: column;
    background-color: #DFDEF8;
    margin: 18px;
    border-radius: 6px;
    position: relative;
    overflow: visible;
    z-index: 0;
}

.mainText {
    line-height: 1.3em;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: $color-blue-1;
    text-transform: capitalize;
}

.typeSwitch {
    display: flex;
    justify-items: center;
    justify-content: space-evenly;
    gap: 10px;
    width: (100% -16px);
    padding: 0 16px;
    >div {
        width: calc(50% - 8px);
        &.active {
            border-bottom: 1px solid $type-text-color-active;
        }
        >h2 {
            text-decoration: none;
            color: $type-text-color;
            font-weight: 600;
            text-align: center;
            font-size: 18px;
            cursor: pointer;
            &.active {
                color: $type-text-color-active;
            }
        }
    }
}

.planSlider {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    gap: 1rem;
    margin-inline: 8px;
    position: relative;

    >p {
        font-weight: bold;
        color: $type-text-color;
        &.active {
            color: $type-text-color-active;
        }
        width: 100%;
        text-align: center;
        cursor: pointer;
		z-index: 15;
    }

    >.slider {
        position: absolute;
        top: 50%;
        width: 50%;
        height: 40px;
        background-color: white;
        border-radius: 3px;
        transform: translateY(-50%);
        transition: .5s;
        
        &.monthly {
            left: 0;
        }

        &.annually {
            left: 50%;
        }
    }
}

.slider-badge {
    background-color: #F6A623;
    border-radius: 3px 3px 0 3px;
    position: absolute;
    top: -5%;
    right: -6px;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #fff;
    padding: 4px 12px;

    &::after {
        opacity: 1;
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        right: -6px;
        top: 16px;
        bottom: -40px;
        border: 6px solid;
        border-color: transparent transparent transparent #F17F21;
        z-index: -1;
    }
}

.cardWrapper {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(220px, 1fr));
    gap: 20px;

    &.plan {
        margin: 18px;
    }

    &.arMarker {
        padding: 38px 16px 0px;
    }

	.basic {
		background-color: #7075B5 !important;
	}

	.medium {
		background-color: #9C76C7 !important;
	}

	.premium {
		background-color: #D15293 !important;
	}

	.individual {
		background-color: #2A3983 !important;
	}

	.school-institutional {
		background-color: #F05E69 !important;
	}

	.edu-button {
		width: 100%;
	}

	>.plan-wrapper {
		display: flex;
		justify-content: space-between;
		height: 100%;
		gap: 5px;

		>.tab-option {
			width: 100%;
			padding-bottom: 18px;
			border-radius: 6px;
			background-color: #DFDEF8;
			background-image: url('../../icons/PlanBackground.svg');
			background-repeat: no-repeat;
			background-size: contain;
			cursor: pointer;
			color: #34446A;
			margin-block: 5px;

			.icon-wrapper {
				min-height: 110px;
			}

            .warn-text {
                color: red;
                font-weight: 700;
                font-size: 10px;
            }

			p {
				margin: 0;
				padding-inline: 15px;
				display: block;
	
				&.name { 
					font-weight: 700;
					font-size: 14px;
					line-height: 19px;
				}
	
				&.price { 
					font-weight: 700;
					font-size: 24px;
					line-height: 33px;
				}

				&.hidden { display: none; }
			}
		}

		.selected { 
			background-image: url('../../icons/PlanBackgroundTransparent.svg');
			border-radius: 6px 6px 0 0; 
			color: #fff;
			margin-bottom: 0;
		}
	}

	>.tab-desc {
		padding: 20px;
		color: #fff;
		display: grid;
		row-gap: 12px;
		border-radius: 0 0 6px 6px;

		>.desc-wrapper {
			display: flex;
			align-items: center;
			column-gap: 16px;

			>.circle {
				width: 26px;
				height: 26px;
				background-color: #EAF9F1;
				border-radius: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&.tab-edu {
			border-radius: 0;
		}
	}
    
    >.card {
        min-height: max-content;
        padding: 20px 20px;
        position: relative;
        overflow: hidden;
        background: #F3F8FE;

        >.cardBody {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 80px;
            height: auto;
            transition: all 1s;

            &.plans {
                max-height: fit-content !important;
            }

            &.open {
                max-height: 400px !important;
            }

            >div {
                display: flex;

                &.drop-down {
                    flex-direction: column;
                    align-items: flex-start;
                    display: flex !important;

                    >div {
                        align-items: center;
                        justify-content: flex-start;
                        display: flex;
                    }
                }
                .plan-cube {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    gap: 10px;
                    width: 80px;
                    height: 80px;
                    background: #7075B5;
                    box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);
                    border-radius: 8px;
                    overflow: hidden;

                    &.skeleton {
                        opacity: 0.7;
                        animation: skeleton-loading 1s linear alternate infinite;
                    }

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    >svg {
                        width: 80px;
                        height: 80px;
                    }
                }
    
                .plan-name {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 6px 16px;
    
                    .plan-name-title {
                        font-weight: 800;
                        font-size: 16px;
                        line-height: 125%;
                        display: flex;
                        align-items: flex-end;
                        color: #001741;
                        text-transform: capitalize;
                        margin: 0px;

                        &.bundle-title {
                            margin-right: 16px;
                        }

                        &.skeleton {
                            width: 80px;
                            height: 20px;
                            opacity: 0.7;
                            animation: skeleton-loading 1s linear alternate infinite;
                            border-radius: 6px;
                        }
                    }

                    > .detail {
                        > .bundle-container {
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          > .bundle-name {
                            font-weight: 800;
                            font-size: 16px;
                            margin-right: 16px;
                          }
                          // > img {
                          // }
                        }
                        > .bundle-owner {
                            font-family: 'Nunito Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 19px;
                            color: #001741;
                            margin-top: 10px;

                            >span {
                                font-weight: 700;
                            }
                        }
                    }
    
                }
                
                .plan-cta-dropdown {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    // width: 54%;
                    // position: relative;
    
                    p {
                        font-family: 'Nunito Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        color: #205FF4;
                        line-height: 19px;
                        position: absolute;
                        right: 16px;
                        cursor: pointer;
                    }
                }
            }


            >.cardBodyHeader {
                display: flex;
                justify-content: space-between;

                >.price-name {
                    display: flex;
                    flex-direction: column;

                    >.title {
                        font-family: 'Nunito', sans-serif;
                        font-weight: 600;
                        font-size: 14px;
                        text-transform: uppercase;
        
                        &.arMarker {
                            color: $color-blue-1;
                        }
                    }
            
                    >.price {
                        font-family: 'Nunito', sans-serif;
                        font-weight: 500;
                        font-size: 30px;
                        margin: 0;
                        
                        &.eduPrice {
                            color: white;
                        }
        
                        &.planPrice {
                            color: #2b2f76;
                            bottom: 15px;
                        }
        
                        &.ind {
                            bottom: 28px;
                        }
        
                        &.sch {
                            bottom: 47px;
                        }
        
                        &.arMarker {
                            color: #2b2f76;
                            top: 0px;
                        }
                    }
                }
    
            }
            >.imageEdu {
                position: absolute;
                top: 0px;
                right: 0px;
            }

            >.title {
                font-family: 'Nunito', sans-serif;
                font-weight: 600;
                font-size: 14px;
                text-transform: uppercase;

                &.arMarker {
                    color: $color-blue-1;
                }
            }
    
            >.price {
                position: absolute;
                right: 0px;
                font-family: 'Nunito', sans-serif;
                font-weight: 500;
                font-size: 30px;
                margin: 0;
                
                &.eduPrice {
                    color: white;
                }

                &.planPrice {
                    color: #2b2f76;
                    bottom: 15px;
                }

                &.ind {
                    bottom: 28px;
                }

                &.sch {
                    bottom: 47px;
                }

                &.arMarker {
                    color: #2b2f76;
                    top: 0px;
                }
            }

            >.note {
                position: absolute;
                bottom: 28px;
                right: 0px;
                font-family: 'Nunito', sans-serif;
                font-weight: 300;
                font-size: 14px;
                color: white;
                margin: 0;
            }

            >.note-2 {
                position: absolute;
                bottom: 10px;
                right: 0px;
                font-family: 'Nunito', sans-serif;
                font-weight: 300;
                font-size: 14px;
                color: white;
                margin: 0;

                &.hidden {
                    display: none;
                }
            }
    
            >div {
                // display: flex;
                align-items: center;
                font-size: 12px;
                gap: 5px;
                display: grid;
                grid-template-columns: 80px auto 20px;
    
                >.circle {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    
                    &.edu {
                        background-color: white;
                    }
                    
                    &.plan {
                        background-color: $color-blue-3;
                    }
                }
                >.description {
                    padding: 3px 10px;
                    border-radius: 50%;
                }
            }

            >.containerInput {
                width: calc(100% - 40%);
                margin: 0px auto;
                justify-content: space-between;
                color: $color-blue-1;
                >span {
                    cursor: pointer;
                }
                >.inputMarker {
                    text-align: center;
                    padding: 10px 5px;
                    width: 30%;
                    color: $color-blue-1;
                    border: 1px solid #D3D6E9;
                    border-radius: 6px;
                    font-size: 16px;
                    font-weight: 800;
                }
            }

        }

        >.button {
            display: flex;
            justify-content: center;
            justify-items: center;
            padding: 10px 10px;
            width: calc(100% - 20px);
            min-width: 200px;
            margin: 10px 0;
            border: 1px solid #D3D6E9;
            border-radius: 10px;
            background-color: $color-button-blue;
            color: white;
            cursor: pointer;

            &.freeTablet {
                width: calc(100% - 60px) !important;
                position: absolute !important;
                bottom: 20px
            }

            &.arMarker {
                color: white !important;
            }

            &.eduCardIn {
                color: white;
                background-color: $color-blue-4 !important;
            }
            
            &.eduCardSch {
                color: white;
                background-color: $color-red !important;
            }
        }

        &.hidden {
            display: none;
        }

        &.eduCardIn {
            color: white;
            background-color: $color-blue-4;
        }
        
        &.eduCardSch {
            color: white;
            background-color: $color-red;
        }
    }

    &.tablet {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr !important;
        gap: 24px !important;
    }

    &.edu-tablet {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        gap: 24px !important;
    }

    &.redeem {
        grid-template-columns: 1fr !important;
        width:calc(100% - 36px) !important;
    }

    &.free-voucher {
        width: auto !important;
    }

    &.mobile-card {
        width: auto !important;
        position: relative !important;
    }

    &.fixed-card {
        position: absolute;
    }

}

.cardflex {
	display: flex;
	flex-direction: column;
	gap: 0
}

.button-edu-wrapper {
	border-radius: 0 0 8px 8px;

	>.button {
		background-color: transparent;
		border: 1px solid #fff;
		margin: 0 20px 20px;
        background: transparent;
        justify-content: center;
	}
}

.sticky-button {
	border-top: 1px solid #d3d6e9;
    bottom: 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
    left: 50%;
    margin-inline: auto;
    padding: 16px;
    position: fixed;
    transform: translateX(-50%);
    width: 100%;
	z-index: 2;
	background-color: #fff;

	>.button {
		margin-inline: auto;
		max-width: 400px;
		padding: 12px;
		justify-content: space-between;
        background: #205FF4;
        height: auto;

		span:last-child {
			font-weight: 900;
		}
	}
}

.planTitle {
    width: auto;
    margin: 30px 20px 20px;
    font-size: 14px;
    color: $color-blue-1;
    font-weight: 600;
}

.nav {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 10;
    background-color: #ffffff;

    &.nav-redeem {
        padding-bottom: 0px !important;
    }
}

.header {
    width: 100%;
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    >span {
        color: $color-blue-1;
        position: absolute;
        left: 16px;
        top: 16px;
        cursor: pointer;
        z-index: 1;
    }
    >.headerTitle {
        width: 100%;
        font-size: 18px;
        text-align: left;
        padding-left: 20px;
        font-weight: 700;
        color: $color-black;
    }

    >.redeemCode {
        background-color: $color-button-blue;
        border-radius: 6px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 16px;
        cursor: pointer;
        
        >.ticket {
            color: white;
        }
    }

    &.checkout {
        border-bottom: 1px solid #D3D6E9;
    }

    &.top-nav-header {
        margin: 15px 0px;
    }
}

.tabSwitch {
    width: max-content;
    margin: 0 auto;
    background-color: #DFE8FF;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    font-weight: 600;
    >p {
        font-size: 14px;
        color: #205FF4;
        padding: 6px 14px;
        cursor: pointer;
        margin: 0;
        text-align: center;
        &.active {
            color: white;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
            font-weight: 600;
            background-color: #205FF4;
            border-radius: 6px;
        }
        &.active-plan {
            color: white;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
            font-weight: 600;
            background-color: #205FF4;
            border-radius: 6px;
        }
        &.active-marker {
            color: white;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
            font-weight: 600;
            background-color: #205FF4;
            border-radius: 6px;
        }

        &.plan {
            padding: 6px 28px !important;
        }
        
        &.tablet {
            padding: 13px 35px !important;
        }

        &.tablet-plan {
            padding: 13px 55px !important;
        }
    }

    &.tablet {
        width: 540px !important;
    }
}

.loading-container {
    width: 100%;
    height: 96vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payHeader {
    margin: 16px;
    background-color: $color-button-blue;
    display: flex;
    justify-content: space-evenly;
    padding: 14px 16px;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    >div {
        display: flex;
        width: 60%;
        flex-direction: column;
        color: white;
        >.first {
            font-weight: 600;
            font-size: 12px;
            margin: 5px 0;
            padding: 0px 5px;
        }
        >.second {
            line-height: 20px;
            font-weight: bold;
            font-size: 18px;
            margin: 5px 0;
            padding: 0px 5px;
        }
        .left {
            text-align: right;
        }
    }
    >.button {
        font-weight: 700;
        margin: 32px 8px;
        font-size: 14px;
        width: auto;
        padding: 10px 40px;
        background-color: white;
        color: $color-button-blue;
        border-radius: 6px;
        cursor: pointer;

        &.paymentWaiting {
            color: $color-orange !important;
            margin: 32px 0px !important;
            padding: 10px 8px !important;
        }
    }
    &.paymentWaiting {
        background-color: $color-orange !important;
    }

    &.tablet {
        justify-content: space-between !important;
        padding: 14px 20% !important;
    }
}

.overviewContent {
    display: grid;
    grid-template-rows: 1fr;
    gap: 2rem;
}

.bodyOverview {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 32px);
    margin: 0 auto;
    gap: 1rem;

    >.bodyCard {
        background-color: white;
        border-radius: 6px;
        font-weight: bold;

        &.rightCard {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
        }

        &.leftCard {
            padding: 15px;
            width: 40%;

            &.tablet {
                padding: 30px 5px !important;
                display: grid !important;
                grid-template-columns: 1fr !important;
                align-items: center;
                gap: 1rem;
                width: calc(100% - 12px) !important;

                >div {
                    display: grid;
                    justify-self: center;
                    align-items: center;
                    position: relative;

                    >.icon {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        cursor: pointer;
                    }

                    >.progress-border {
                        margin: auto;
                    }
                }
            }

            &.desktop {
                padding: 30px 5px !important;
                display: grid !important;
                grid-template-columns: 1fr !important;
                align-items: center;
                gap: 1rem;
                width: 100% !important;

                >div {
                    display: grid;
                    justify-self: center;

                    >h3.custom {
                        font-size: 20px !important;
                    }
                }
            }
        }

        &.seats {
            padding: 12px;
            display: flex;

            >.seatsHeader {
                width: 100%;
                position: relative;

                >.seatsInfo {
                    display: flex;
                    justify-content: space-between;

                    >div {
                        >.circle {
                            width: 40px;
                            height: 40px;
                            border-radius: 100%;
                            
                            @for $i from 1 to 5 {
                                &:nth-child(#{$i}) {
                                    border: white 1px solid;
                                    background-color: rgba(128, 128, 128);
                                    position: relative;
                                    left: calc($i * -1rem);
                                }
                            }
                        }

                        &.profile {
                            display: flex;
                            padding: 0 0 0 16px;
                            position: absolute;
                        }
                    }
                }                
            }
        }
        
        h3 {
            text-align: center;
            font-size: 32px;
            
            &.rightCard {
                padding: 0 15px;
                margin: 5px auto;
                text-align: left;
                font-size: 18px;
            }
            
            &.leftCard {
                text-align: center;
                font-size: 32px;
                margin: 14px auto;
            }

            &.tablet {
                font-size: 28px !important;
            }
            
        }
        
        p {
            text-align: center;
            color: $color-blue-2;
            font-size: 14px;
            
            &.rightCard {
                margin: 5px auto;
                padding: 0 15px;
                text-align: left;
            }
            
            &.leftCard {
                text-align: center;
            }

            &.seats {
                text-align: left !important;
            }

            &.seatsAvail {
                width: 60%;
                max-width: 200px;
                padding-left: 80px;
                color: $color-black !important;
            }
        }
    }

    &.seats {
        display: grid !important;
        grid-template-columns: 1fr;
    }

    &.tablet {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}

.goToDetail {
    position: absolute;
    right: 0px;
    top: 40%;
    height: 20%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleInput {
    width: calc(100% - 32px);
    margin: 20px 0 12px 0;
    padding: 0 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $color-blue-1;

    &.redeem_input {
        padding: 0px !important;
    }
}

.formRedeem {
    display: flex;
    width: calc(100% - 32px);
    padding: 0 16px;
    justify-content: space-between;
    align-items: stretch;
    gap: 8px;

    >.submitRedeem {
        background-color: $color-button-blue;
        color: white;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        justify-items: center;
        align-items: center;
        padding: 15px;
        width: auto;

        span {
            font-size: 18px;
        }
    }

    >.inputRedeem {
        background-color: white;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #D3D6E9;
        padding: 0 0 0 16px;
        color: $color-blue-1;

        &::placeholder {
            color: $color-blue-2;
        }
    }
}

.image-thumbnail {
    border-radius: 1rem;
}

.locked {
    border: none !important;
    cursor: not-allowed !important;
}

.image-overview {
    position: absolute;
    left: 10px;
}

.close-button-retry {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
}

@keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
  
    100% {
      background-color: hsl(200, 20%, 95%);
    }
}