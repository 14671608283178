@import "../Sass/variable";

.App {
  height: 100%;
  min-height: 100vh;
  background-color: $bg-page;
}

.Main {
  background-color: white;
  &.checkout {
    padding: 0px;
    position: relative;
  }

  &.tablet {
    height: 100%;
    min-height: 100vh;
  }

  &.landscape {
      height: 100% !important;
      min-height: 100vh;
  }

  &.marker {
    height: 100% !important;
    min-height: 100vh !important;
  }
}

.contentContainer {
  min-height: calc(100vh - 180px - 60px);
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  // .line-separator {
  //     position: absolute;
  //     width: 10px;
  //     height: 100%;
  //     background-color: #205FF4;
  //     top : 0;
  //     left: 50%;
  //     transform: translate(-50%, 0);
  // }

  

  &.app-container {
    min-height: calc(100vh - 230px);
  }

  .left-title {
    margin-left: 26px;
  }

  .right-title {
    margin-left: 18px;
  }

  &.tablet {
    gap: 0rem !important;
    grid-template-columns: 1fr !important;
    max-width: 480px;
    margin: 0px auto;
    height: 100%;
    min-height: calc(100vh - 200px);

    // .left-container {
    // }

    .right-container {
      border-left: 1px solid #d3d6e9;
    }
  }
}

.bundle {
  width: calc(100% - 52px);
  margin: 0 26px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001741;

  > .content-data {
    height: 100%;
    width: 75%;
    display: flex;
    align-items: center;
    > .image-container {
      margin-right: 20px;
      min-width: 100px;
      height: 100px;
      border-radius: 6px;
      > img {
        height: 100%;
        width: 100%;
      }
    }
    > .detail {
      > .bundle-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .bundle-name {
          font-weight: 800;
          font-size: 16px;
          margin-right: 16px;
        }
        // > img {
        // }
      }
      > .bundle-owner {
        margin-top: 10px;
      }
    }
  }

  > .content-detail {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    > .content-price {
      > .price {
        font-weight: 800;
        font-size: 20px;

        margin-bottom: 10px;
      }
      > .button-remove {
        text-align: right;
        cursor: pointer;
      }
    }
  }
}

.mobile {
  > .content-data {
    > .detail {
      > .bundle-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: flex-start;

        
        > img {
          margin-bottom: 6px;
        }
      }
    }
  }
}

.emblem-container {
  background: #34446A;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  color: white;
  padding: 5px 10px;
  border-radius: 24px;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  font-family: "Nunito Sans";
  opacity: 1;
  transition: opacity 0.3s;
  background-color: #F6A623;
  color: #001741;
  min-width: max-content;

  &.special {
    background: linear-gradient(
      94.79deg,
      #8398f8 4.52%,
      #db1d8f 48.12%,
      #f89e03 92.64%
    ) !important;
    color: white !important;
  }

  >img {
    margin-right: 8px;
  }

  >p {
    margin: 0px !important;
  }
}

.back {
  text-decoration: none;
  position: absolute;
  text-align: right;
  right: 20px;
}

.cardCurrent {
  cursor: pointer;
  width: $width-section;
  margin: 16px;
  background-color: #205ff4;
  box-shadow: 0px 4px 31px rgba(32, 95, 244, 0.21);
  border-radius: 6px;
  color: white;
  padding: 16px;
  position: relative;
  height: fit-content;

  .image {
    position: absolute;
    top: 50px;
    right: 16px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin: 8px 0;

    &.title {
      font-size: 12px;
    }

    &.plan {
      font-family: "Montserrat", sans-serif;
      font-size: 24px;
      font-weight: bold;
      margin: 8px 0 24px 0;
    }
  }

  &.individual {
    background-color: #2a3883 !important;
  }

  &.sch {
    background-color: $color-red !important;
  }

  &.tablet {
    position: fixed;
    width: 45% !important;
  }
}

.paymentScheme {
  width: calc(100% - 32px);
  margin: 20px 18px;

  > .button_dropdown {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  > .summary-container {
    display: none;
    > .summary-section {
      display: flex;
      justify-content: space-between;
      > p {
        color: #001741;
        font-weight: 700;
        font-size: 16px;
      }
      .total-price {
        font-weight: 800;
        font-size: 22px;
      }
    }
  }
  > .drop {
    display: block;
  }

  &.bundle_success {
    width: 60% !important;
  }

  p {
    color: $color-blue-1;
    font-size: 16px;
    font-weight: 700;

    &.desc {
      color: $color-blue-2;
      font-size: 11px;
      font-weight: 400;

      &.method {
        height: max-content;
        margin: auto 0;
      }
    }
  }

  .custom {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: $color-blue-1;
    gap: 2px;

    > div {
      padding: 0 8px;
      display: flex !important;
      justify-content: center !important;
      gap: 2px;
      align-items: center !important;

      > input {
        text-align: center;
        padding: 5px;
        width: 40px;
        color: $color-blue-1;
        font-size: 14px !important;
        font-weight: 800;
      }

      > span {
        color: $color-blue-1;
        cursor: pointer;
      }
    }

    > p {
      font-size: 18px !important;
      margin: 0px !important;
      padding: 0px 8px !important;
    }
  }

  .paymentWrapper {
    display: grid;
    justify-content: space-between;
    gap: 16px;
    grid-template-columns: 1fr 1fr;

    &.custom-blr-coin {
      gap: 8px;
      grid-template-columns: 1fr 1fr;

      >.period {
        >.custom {
          gap: 0px;

          >.input-duration {
            width: 100%;

            >.blr-coin {
              padding: 0px;
            }
          }
        }
      }
    }

    &.blr-coin {
      gap: 8px;
      grid-template-columns: repeat(3, minmax(90px, 144px));
      margin: 14px 0px;

      >.period {
        max-height: 138px;
        max-width: 144px;
        background: #FFFFFF;
        border: 1px solid rgba(55, 69, 103, 0.5);
        border-radius: 10px;
        align-items: center;
        padding: 2px 8px;
        width: calc(100% - 20px);

        &.active {
          background: #F3F8FE;
          border: 2px solid #539D2F;
        }

        >p {
          font-weight: 800;
          font-size: 14px;
          line-height: 125%;
          display: flex;
          align-items: flex-end;
          color: #34446A;

          >span {
            margin-left: 8px;
            font-size: 10px;
          }
        }

        >svg {
          width: 32px;
        }

        >.desc {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: #5CB035;
        }
      }
    }

    > div {
      cursor: pointer;
      width: 100%;
      background-color: white;
      border-radius: 6px;
      padding: 16px 0;
      border: 1px solid #d3d6e90e;
      p {
        // padding: 0 16px;
        margin: 4px 0;

        > input {
          margin: 5px 12px 5px 0;
          cursor: pointer;
        }
      }

      .payment-text-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
      }

      &.active {
        border: 3px solid #498729;
      }

      &.period {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 8px 16px;
        min-height: auto;
        background: #f3f8fe;
        width: calc(100% - 40px);

        &.total-price {
          align-items: flex-end;
          background: transparent;
          text-align: left;
          /* padding: 8px 0 !important; */
          min-height: auto !important;
          /* min-width: 40%; */
          overflow: hidden;
          padding: 0px !important;
          width: calc(100% - 8px);

          >.total {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 22px;
            color: #0940C3;
          }
        }

        > p {
          font-weight: 700;
          font-size: 16px;
          color: #001741;

          &.desc {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 22px;
            color: #0940C3;
          }
        }
      }
      &.period-custom {
        text-align: left;
        min-height: auto !important;
        overflow: hidden;
        padding: 0px 8px !important;
        width: calc(100% - 24px);
        > .custom {
          display: flex;
          gap: 10px;
          height: 100%;
          background: #f3f8fe;
          width: calc(100%);
          padding: 10px 0px;
          > .input-duration {
            height: 40px;
            width: 40%;
            // border: 1px solid #d3d6e9;
            border-radius: 6px;
            overflow: hidden;
            > input {
              background-color: transparent !important;
              border: none;
              height: 100%;
              width: 25px;

              &.blr-coin {
                width: 100%;
              }
            }
            > p {
              margin: 0;
              padding: 0 4px;
            }
          }
          > .button-duration {
            border-radius: 16px;
            width: 30px;
            height: 30px;
            aspect-ratio: 30/30;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            > .minus {
              outline: 1px solid #999999;
              border-radius: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              color: #34446a;
              font-size: 20px;
              background: #f4f4f4;
            }
            > .plus {
              outline: 1px solid #999999;
              border-radius: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              color: white;
              font-size: 20px;
              background: #205ff4;
              font-size: 20px;
            }
          }
        }
        > .total {
          font-weight: 700;
          font-size: 24px;
        }
        > p {
          font-weight: 700;
          font-size: 16px;
          color: #001741;

          &.desc {
            font-size: 16px;
            font-weight: 700;
            color: $color-blue-1;
          }
        }
      }

      &.method {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        background: #f3f8fe;
        width: calc(100% - 40px);

        > p {
          padding: 0px;
        }
      }

      &.seats {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        text-align: start;
        width: 100% !important;
        padding: 16px;
        cursor: default;

        > span {
          color: $color-blue-1;
          cursor: pointer;
        }

        > input {
          text-align: center;
          padding: 10px 5px;
          width: 15%;
          color: $color-blue-1;
          font-size: 16px;
          font-weight: 800;
        }

        > p {
          width: 30%;
          font-size: 16px;
          font-weight: 800;
        }

        > div {
          width: 50%;
          text-align: end;

          > p {
            font-weight: 800;
            padding: 0px;
            color: $color-blue-1;

            &.desc {
              font-size: 12px;
            }
          }
        }
      }
    }

    &.paymentMethod {
      grid-template-columns: 1fr !important;
    }

    .button {
      background-color: $color-button-blue;
      width: calc(100% - 32px);
      margin: 32px 16px;
      padding: 13px 0;
      color: white;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      border-radius: 6px;
      cursor: pointer;
    }

    &.seats {
      grid-template-columns: 1fr !important;
      margin-right: 32px;
    }
  }

  .prices {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 32px);
    padding: 0px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    > p {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.2);
      font-weight: 800;

      &.active {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &.tablet {
    width: auto !important;
  }
}

.checkout-button {
  width: 100%;
  position: sticky;
  bottom: 0px;
  border: 1px solid #dfe8ff;
  padding: 10px 0% !important;
  display: flex;

  justify-content: center;
  background: white;

  &.right-button {
    justify-self: center;
    justify-content: center !important;
  }
}

.end-user-container {
  width: calc(100% - 32px);
  margin: 0px 18px;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  gap: 10px;
  height: 48px;
  background: linear-gradient(109.69deg, #498729 0%, #62BE38 100%);
  border-radius: 8px;
  width: calc(100% - 64px);
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.disable {
  opacity: 30%;
  cursor: default !important;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  border-bottom: 5px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader2 {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: 20px;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  padding: 3px;
}

.loader2 {
  display: block;
  width: 30px;
  min-width: 30px;
  min-height: 30px;
  height: 30px;
  border-radius: 50%;
  animation: spin 1s ease infinite;
  -webkit-animation: spin 1s infinite;
  border: 3px solid transparent;
  border-top-color: #24b9e1;
  background-color: #fff;
  box-sizing: border-box;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.desc-end-user-container {
  display: flex;
  gap: 0.5rem;
}

.desc-end-user {
  color: #2a3884;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.voucher-container {
  text-align: left;
  // padding: 10px;
  // margin: 0px 12px;
  width: 100%;
  font-size: 14px !important;
  display: flex;
  justify-content: space-between;
}

.voucher-input {
  color: $color-blue-1 !important;
  width: 60%;
  font-size: 16px !important;
  padding: 10px 16px;
  font-weight: 800;
  background: #f3f6f9;
  border: 1px solid #999999;
  border-radius: 6px;
}

::placeholder {
  color: #999999;
  font-weight: 700;
  font-size: 15px;
}

.voucher-view {
  width: 25%;
  padding: 0 8px;
  background: linear-gradient(109.69deg, #498729 0%, #62BE38 100%);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  &.button-search {
    cursor: pointer;
  }

  > p {
    color: white;
    padding: 0px !important;
    margin: 0px !important;
  }

  > span {
    color: white;
  }
}

.button-disable {
  cursor: not-allowed !important;
}

.error-voucher {
  color: $color-red !important;
  font-size: 14px !important;
}

.custom-price-discount {
  display: flex;
  flex-direction: column;
}

.search-button-blue {
  background-color: #205ff4 !important;
}

.search-button-red {
  background: #ff0000 !important;
}

.error-status-voucher {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  > p {
    color: white !important;
    margin: 0px;
  }
}

.grey {
  color: #818dab !important;
  margin: 0px;
  margin-bottom: 10px;
}

.disable-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.emblem {
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    width: 85px;
    height: 24px;
    background: linear-gradient(94.79deg, rgba(131, 152, 248, 0.8) 4.52%, rgba(219, 29, 143, 0.8) 48.12%, rgba(248, 158, 3, 0.8) 92.64%);
    border-radius: 50px;
    color: white;

    svg {
        margin-right: 5px;
    }
}

@media only screen and (max-width: 700px) {
  .paymentWrapper {

    > div {

      &.method {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        background: #f3f8fe;

        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          color: #001741;

          &.desc {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 100%;
            color: #5E6AB0;
          }
        }

        .payment-text-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: 100%;
        }

      }

    }
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: sticky;
  bottom: 0px;
  height: 165px;
  justify-content: center;
  align-items: flex-start;
  border-top: 1px solid #D3D6E9;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  padding-top: 10px;

  &.mobile-only {
    padding-bottom: 60px;
  }

  &.not-login {
    height: fit-content;
    padding-bottom: 0px;
  }

  >div {
    max-width: 480px;
    margin: 10px auto;
  }
  
  .default-button-desc {
    width: calc(100% - 32px);
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #34446A;
    padding: 0px 16px;

    >a {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      text-decoration: underline;
      line-height: 14px;
      color: #34446A;
    }
  }
}

@media only screen and (min-width: 768px) {

  .checkout-button {
    width: 100%;
    position: sticky;
    bottom: 0px;
    padding: 10px 0% !important;
    display: flex;
    flex-direction: column;
    max-width: 60%;
    border: none !important;
    justify-content: center;
    background: white;

    &.right-button {
      justify-self: center;
      justify-content: center !important;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    position: sticky;
    bottom: 0px;
    height: 165px;
    justify-content: center;
    align-items: flex-start;
    border-top: 1px solid #D3D6E9;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    padding-top: 10px;
  
    >div {
      max-width: 480px;
      margin: 10px auto;
    }
    
    .default-button-desc {
      width: calc(100% - 32px);
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #34446A;
      padding: 0px 16px;
      text-align: center;
  
      >a {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        text-decoration: underline;
        line-height: 14px;
        color: #34446A;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .input-duration {
    height: 40px;
    width: 40%;
    // border: 1px solid #d3d6e9;
    border-radius: 6px;
    overflow: hidden;
    > input {
      border: none;
      background-color: transparent !important;
      height: 100%;
      width: 25px;
    }
    > p {
      display: none;
      margin: 0;
      padding: 0 4px;
    }
  }
}

.disc-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 5px;
  gap: 10px;

  width: 45px;
  height: 15px;

  background: #4A892A;
  border-radius: 4px;
}