@import '../Sass/variable';

html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0px;
    display: none;
}
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.user-redeem {
    position: absolute;
    bottom: 20px;
}

.card-redeem {
    padding: 0 !important;
}

.card-redeem-body {
    padding: 16px 20px !important;
    overflow: hidden !important;

    &.marker {
        padding: 80px 20px !important;
    }
}

.card-section {
    display: flex;
    align-items: center;
    &.card-header {
        position: relative;
        overflow: hidden;
        padding: 10px 66px;
        background: #7075B5;
        border-bottom: 1px solid #E9EAF4;
        >.card-name-redeem {
            margin-right: 16px;
        }
        >.plan-design {
            position: absolute;
            right: 0;
        }
    }

    &.card-footer {
        border-top: 1px solid #E9EAF4;
        min-height: 60px !important;
    }

    .title {
        font-style: normal;
        font-weight: bolder;
        font-size: 18px;
        margin: 5px 0 !important;
        color: rgb(255, 255, 255);

        &.free {
            text-align: left;
        }

        &.checkout-price {
            font-size: 28px !important;
            line-height: 30px !important;
            margin: 0px !important;
        }
    }

    .period {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        min-height: 16px;
        margin: 5px 0 !important;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #848FCE;
    }
}

.default-price {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;

    &.footer {
        justify-content: flex-end;
    }

    >.voucher-status {
        font-weight: bolder;
        color: white;
        background: #24B9E1;
        border-radius: 4px;
        padding: 3px 8px !important;
    }

    >.checkout-detail-voucher {
        width: 100%;
        text-align: center;
    }
}

.voucher-applied {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #001741;
    opacity: 0.5;
    margin: 3px 0 !important;
}


.redeem {
    .button {
        position: relative !important;
        width: calc(100% - 32px) !important;
        margin: 0 !important;
        text-align: center;
        justify-content: center;

        &.proceed {
            align-self: flex-end !important;
            z-index: 10000;
        }

        &.hidden {
            display: none;
        }
    }

    >.contentRedeem {
        // display: grid;
        // grid-template-columns: 1fr;
        // justify-items: center;
        display: flex;
        flex-direction: column;
        // justify-items: center;
        min-height: calc(100vh - 200px);
        padding-bottom: 0px;

        &.mobile-only {
            min-height: 55vh;
        }

        >.redeemCode {
            width: 100%;

            &.bundle_success {
                margin-top: 130px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
             
            >.formRedeem {
                width: calc(100% - 32px);
                padding: 0 16px;

                >span {
                    cursor: pointer;
                }
            }
        }
        
        &.tablet {
            grid-template-columns: 1fr !important;
            justify-items: center !important;
            align-items: center !important;
            width: 100%;

            >.redeemCode {
                width: 100%;
                padding: 0 16px;

                &.bundle_success {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
                 
                >.formRedeem {
                    width: calc(100% - 32px);
                    padding: 0 16px;

                    >span {
                        cursor: pointer;
                    }
                }
            }

            >.titleInput {
                margin-top: 0px !important;
                width: 60% !important;
            }

            >.validStatus {
                width: 60% !important;
            }

            >.content {
                padding: 5px !important;
                width: 60% !important;
            }

            >.cardWrapper {
                padding: 0px 20px !important;
                margin: 20px 0px !important;
                width: 60% !important;
            }

            >.button {
                width: 60% !important;
            }
        }
    }
}

.contentRedeem {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 70px;

    &.tablet {
        gap: 0rem !important;
    }
}

.icon_party_popper_container {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
}

// .icon_party_popper {
//     position: absolute;
//     top: 0;

//     &.left {
//         left: 0;
//     }
// }

.image {
    &.edu {
        margin: -25px;
        transform: scale(.5);
    }
    &.mobile {
        top: calc(100%/2 - 10px) !important;
        right: 10px !important;
        transform: scale(.8) !important;
    }
    &.width {
        width: 122px;
    }
}

.formRedeem {
    width: calc(100% - 32px);
    padding: 0 16px;
}


.titleInput {
    &.desc {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: $color-blue-5 !important;
    }
    &.invalid {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #E20060 !important;
        padding: 10px 0 !important;
        margin: 0px !important;
    }
    &.bundle_success {
        text-align: center;
        font-weight: 800;
        font-size: 18px;
        line-height: 26px;
        color: #34446A;
    }
}

.validStatus {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: calc(100% - 32px);
    padding: 0 16px;
    color: $color-blue-1;
    font-size: 16px;
    font-weight: 600;

    &.invalid {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: #E20060 !important;    
    }

    &.bundle_success {
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        color: #34446A;
        flex-direction: column;
        gap: 0px;
    }
}

.content {
    width: calc(100% - 32px);
    padding: 0 16px;
    font-size: 14px;
    color: $color-blue-5;
    font-weight: 400;

}

.activePeriod {
    text-align: center;
    size: 16px;
    font-weight: 700;
    color: $color-blue-1;
}

.popup {
    position: fixed;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    >.popupContent {
        border-radius: 6px;
        background-color: white;
        font-size: 16px;
        font-weight: 700;
        color: $color-blue-1;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;

        .text_header_content {
            font-weight: 800;
            font-size: 18px;
            line-height: 26px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            color: #000000;
        }

        .text_conetent_header {
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            color: #000000;
        }

        >div {
            padding: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            >p {
                cursor: pointer;
            }

            &.popupHeader {
                border-bottom: 1px solid rgba(185, 184, 184, 0.301);

                >.error {
                    color: red;
                    padding: 2rem;
                    text-align: center;
                }
            }

            &.switch {
                flex-direction: row !important;
                justify-content: space-around !important;
                width: 100%;

                >.confirm {
                    padding: 8px 16px;
                    border-radius: 6px;
                    background: transparent linear-gradient(
                        100deg,#24b9e1,#7166c4) 0 0 no-repeat padding-box;
                    color: white;
                }
            }
        }

        &.popup-retry {
            position: relative !important;
        }
    }
}

.redeem-voucher-proceed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 32px);
    position: sticky;
    bottom: 0px;
    padding: 10px 16px;
    background-color: white;
    border-top: 1px solid #D3D6E9;

    >.end-user-container {
        width: 100%;
        margin: 0px;
    }

    >.default-button-desc {
        margin: 0px;
    }

    >.button {
        margin: 10px 0px !important;
    }
}

.loader-container {
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.checkout-loading {
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 100000;
        position: fixed;
        top: 0px;
    }
}

.discount-get {
    color: #205FF4 !important;
}

.start-price {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900 !important;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: line-through;
    color: #E20060;
    margin: 5px 0px !important;
    opacity: 0.5;

    &.checkout-start {
        font-size: 18px !important;
    }

    &.checkout-layout {
        font-size: 12px !important;
        color: #FF0000 !important;
        // padding: 0px !important;
    }
}

.final-price {
    font-style: normal;
    font-weight: bolder !important;
    font-size: 18px;
    margin: 5px 0 !important;
    color: $color-blue-4;

    &.price-button {
        font-family: 'Nunito Sans' !important;
        font-style: normal !important;
        font-weight: 900 !important;
        font-size: 16px !important;
        line-height: 22px !important;
        display: flex !important;
        align-items: flex-end !important;
        color: #FFFFFF !important;
    }

    &.checkout-price {
        font-size: 20px !important;
        margin: 0px !important;
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 22px;
        color: #0940C3 !important;
    }

    &.custom-voucher {
        font-size: 18px !important;
        padding: 0px !important;
    }
}

.bundle_list {
    display: flex;
    width: auto;
    // justify-content: center;
    align-items: center;
    gap: 16px;
    // max-width: 60%;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow-x: scroll;

    .bundle_item {
        overflow: hidden;
        margin: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        border: 1px solid #D3D6E9;
        aspect-ratio: 300/200;
    
        >.bundle_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h3 {
        margin: 0 16px;
    }
}

.banner_container_bundle {
    position: relative;

    .banner_image_bundle {
        border-radius: 8px;
        width: 100%;
        // max-height: 180px;
    }

    .banner_text_container_bundle{
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: 80%;
        max-width: 360px;

        .banner_text {
            font-weight: 700 !important;
            font-size: 23px !important;
            line-height: 31px !important;
            color: #001741 !important;
            margin: 0 !important;
        }

        .banner_external_url {
            padding: 7px 10px;
            border: 1px solid #000000;
            border-radius: 4px;
            width: max-content;
            cursor: pointer;

            .url_text {
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                color: #34446A;
                margin: 0 !important;
            }
        }
    }

}


@media only screen and (min-width : 768px) {
    .redeemCode {
        width: 60% !important;
        padding: 0;

        >.formRedeem {
            width: 100% !important;
            padding: 0 !important;
        }
    }

    .bundle_list {
        max-width: 60%;
    }

    .redeem {
    
        >.contentRedeem {
            display: flex;
            flex-direction: column;
            min-height: 70vh;
            padding-bottom: 0px;
        }
    }
  }

.name_count_container {
    display: flex;
    justify-content: space-between;
}

.title_redeem_voucher_bundle {
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #34446A !important;
}

.desc_redeem_voucher_bundle {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #34446A !important;

    &.desc_bundle {
        font-weight: 700 !important;
    }
}

.right {
    right: 16px;
    left: auto !important;
}